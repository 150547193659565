
export default {
  name: "TheMessage",
  props: {
    type: {
      type: String,
      require: true
    },
    message: {
      type: String,
      require: false
    },
  },
  watch: {
    '$route.name': function (newRoute) {
      document.title = newRoute === 'customer-id' ? '' : '顧客管理AGS';
    }
  },
}
